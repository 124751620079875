import React, {useEffect, useRef, useState} from "react";
import {PageHeader, ListGroup, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import { API, Auth } from "aws-amplify";
import {ListGroupItem } from 'reactstrap';
import LoaderButton from "../components/LoaderButton";
import {renderDate as renderEventDate} from '../libs/util';
import {renderDuration as renderEventDuration} from '../libs/util';
import {renderDistance as renderEventLength} from '../libs/util';

export default function UserInfo (props) {
  const [userName, setUserName] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastname, setLastName] = useState("")
  const [description, setDescription] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [distance, setDistance] = useState("");
  const [content, validateForm] = useState("")
  const [userList, setUserList] = useState("")
  const [loaded, setIsLoaded] = useState(false);
  const [location, setLocation] = useState("")
  const [eventRuns, setEventRuns] = useState(null);
  // const [eventName, setEventName] = useState("");
  // const [event, setEvent] = useState(null);


  useEffect(() => {
    async function getUserInfo(){

        //Retreiving user id
        setIsLoaded(true);
        const userId = props.match.params.id;

        //Calling the API to retrieve user info
        var userInfo = await API.get("events","/user/"+userId);

        //Setting up the user info with data retreived by the API
        setUserList(userInfo.records);
        setUserName(userInfo.records[0].userName);
        setFullName(userInfo.records[0].firstName + " " + userInfo.records[0].lastName);
        setDescription(userInfo.records[0].description);
        setLocation(userInfo.records[0].city + ", " + userInfo.records[0].state + ", " + userInfo.records[0].country)
        setCreatedAt(userInfo.records[0].createdAt);

        //Calls the API to retrieve user runs
        const runs = await API.get("events", `/userRuns/${userId}`);
        console.log("Runs: ", runs);
        setEventRuns(runs.records);
      }

    console.log("Loaded ", loaded)
    if (!loaded)
      getUserInfo();

  }, [props.match.params.userId]);

  function renderEventName(eventName){
    console.log("eventName", eventName);
    return eventName
  }

  // function renderEventLength(eventLength){
  //   console.log("eventLength", eventLength);
  //   var convert = require('convert-units')
  //     convert('m').possibilities('mi')
  //       return eventLength
  // }

  return (
    <div className="UserInfo">
          <FormGroup controlId="User Name">
              User Name:
              <FormControl
                  value={userName}
                  type="text"
                  placeholder=""
                  onChange={e => setUserName(e.target.value)}
              />
          </FormGroup>
          <FormGroup controlId="Full Name">
            Full Name:
            <FormControl
                value={fullName}
                type="text"
                placeholder=""
                onChange={e => setFullName(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="Description">
                Description:
                <FormControl
                    value={description}
                    type="text"
                    placeholder=""
                    onChange={e => setDescription(e.target.value)}
                />
          </FormGroup>
          <FormGroup controlId="City, State, Country">
              City, State, Country:
              <FormControl
                  value={location}
                  type="text"
                  placeholder=""
                  onChange={e => setLocation(e.target.value)}
              />
          </FormGroup>
          <FormGroup CountrolId="Memeber Since">
              Member Since:
          <FormControl
            value={createdAt}
            type="text"
            placeholder=""
            onChange={e => setCreatedAt(e.target.value)}
          />
          </FormGroup>

          <div className="runs">
            <ListGroup>
              User Runs:
              {eventRuns && [{}].concat(eventRuns).map((event, i) =>
                  i > 0 ? (
                      <LinkContainer key={i} to={'/'+i}>
                        <ListGroupItem header={"Runner: " + event.userName}>
                          <div>{"Event Name: " + renderEventName(event.eventName)} </div>
                          <div>{"Duration: " + renderEventDuration(event.eventDuration)} </div>
                          <div>{"Length: " + renderEventLength(event.eventLength)}</div>
                          {"Ran on: " + renderEventDate(event.eventDate)}
                        </ListGroupItem>
                      </LinkContainer>
                  ) : (<div/>)
              )
            }
            </ListGroup>
        </div>
    </div>


    );
  }
