import React, { useRef, useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewEvent.css";
import "./react-datetime.css";
import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import Map from "../components/DisplayMap";
import L2 from "leaflet-gpx";
import GpxParser from "../libs/gpx-parse";
import DateTimePicker from 'react-datetime';

export default function NewEvent(props) {
    const file = useRef(null);
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [eventName, setEventName] = useState("");
    const [author, setAuthor] = useState("");
    const [distance, setDistance] = useState("");
    const [elevation, setElevation] = useState("");
    const [elevationMin, setElevationMin] = useState("");
    const [elevationMax, setElevationMax] = useState("");
    const [startPoint, setStartPoint] = useState(false);
    const [endPoint, setEndPoint] = useState(false);
    const [eventDateTime, setEventDateTime] = useState(false);

  let fileReader;

  const handleFileRead = (e) => {
    const fileContent = fileReader.result;
    file.current = fileContent;

    const gpxLayer = new L2.GPX(fileContent, {
          async: true
    }).on('loaded', function (e) {
          setEventName(e.target.get_name());
          setDistance(e.target.get_distance());
          setAuthor(e.target.get_author());
          setElevation(e.target.get_elevation_gain());
          setElevationMin(e.target.get_elevation_min());
          setElevationMax(e.target.get_elevation_max());
          GpxParser.parseGpx(fileContent, function (error, result) {
              if (!error) {
                  if (result.tracks && result.tracks.length) {
                      //We won't consider more than 1 track
                      setStartPoint([result.tracks[0].segments[0][0].lat, result.tracks[0].segments[0][0].lon]);
                      const numPoints = result.tracks[0].segments[0].length;
                      setEndPoint([result.tracks[0].segments[0][numPoints-1].lat, result.tracks[0].segments[0][numPoints-1].lon]);
                  } else if (result.routes && result.routes.length) {
                      //We won't consider more than 1 route
                      //TODO Handle the use case where a route is uploaded
                  }
              } else {
                  return error;
              }
          });

    });

  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  function renderMap() {
        let runMap = null;
        if (distance) {
            runMap =        <Map
                eventFile={file.current}
                courseLat={0}
                courseLong={0}
                eventName={eventName}
                onChange = {e => function () {

                }}
                isLoading={console.log("Loading map...")}
            />
            ;
        } else {
            runMap = "";
        }
        return runMap;
  }


  function validateForm() {
    return content.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
   }

   async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
          `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      // const attachment = file.current
      //     ? await s3Upload(file.current)
      //     : null;
      const attachment = "None";
      const gpxData = file.current;
      const isRoute = 1;
      const eventLength = distance;
      const eventDate = Date.parse(eventDateTime);
      await createEvent({ content, attachment, isRoute, gpxData, startPoint, endPoint, eventName, eventLength, eventDate});
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createEvent(event) {
    //console.log(event);
    return API.post("events", "/events", {
      body: event
    });
  }


  return (
    <div className="NewEvent">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="file">
          <ControlLabel>Let's start with a gpx file</ControlLabel>
          <FormControl onChange={e => handleFileChosen(e.target.files[0])} type="file" />
        </FormGroup>
          {renderMap()}
          <FormGroup controlId="Name">
              Name
              <FormControl
                  value={eventName}
                  type="text"
                  placeholder="Name"
                  onChange={e => setEventName(e.target.value)}
              />
          </FormGroup>
          <FormGroup controlId="content">
          <ControlLabel>Enter an event description </ControlLabel>
          <FormControl
              value={content}
              placeholder="Describe your event"
              componentClass="textarea"
              onChange={e => setContent(e.target.value)}
          />
          </FormGroup>
          Event Date and Time<br/>
          <DateTimePicker
            onChange={e => setEventDateTime(e._d)}
            value={eventDateTime}
          />
          <FormGroup controlId="Distance">
              Distance
              <FormControl
                  value={distance}
                  type="text"
                  placeholder="Distance"
                  onChange={e => setDistance(e.target.value)}
              />
          </FormGroup>
          <FormGroup controlId="elevation">
              Elevation
              <FormControl
                  value={elevation}
                  type="text"
                  placeholder="Elevation"
                  onChange={e => setElevation(e.target.value)}
              />
          </FormGroup>
          <FormGroup controlId="elevationMin">
              Elevation Min
              <FormControl
                  value={elevationMin}
                  type="text"
                  placeholder="Elevation Min"
                  onChange={e => setElevationMin(e.target.value)}
              />
          </FormGroup>
          <FormGroup controlId="elevationMax">
              Elevation Max
              <FormControl
                  value={elevationMax}
                  type="text"
                  placeholder="Elevation Max"
                  onChange={e => setElevationMax(e.target.value)}
              />
          </FormGroup>
          <FormGroup controlId="start">
              Start
              <FormControl
                  value={startPoint}
                  type="text"
                  placeholder="start"
                  onChange={e => setStartPoint(e.target.value)}
              />
          </FormGroup>
          <FormGroup controlId="end">
              End
              <FormControl
                  value={endPoint}
                  type="text"
                  placeholder="end"
                  onChange={e => setEndPoint(e.target.value)}
              />
          </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </form>
    </div>
  );
}
