import React, {useEffect, useRef, useState} from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import "./NewEvent.css";
import { API } from "aws-amplify";
import GpxParser from "../libs/gpx-parse";
import L2 from "leaflet-gpx";
import Map from "../components/DisplayMap";

export default function Run(props) {
  const file = useRef(null);
  const [event, setEvent] = useState("");
  const [runName, setRunName] = useState("");
  const [author, setAuthor] = useState("");
  const [cadence, setCadence] = useState("");
  const [distance, setDistance] = useState("");
  const [elevation, setElevation] = useState("");
  const [elevationMin, setElevationMin] = useState("");
  const [elevationMax, setElevationMax] = useState("");
  const [duration, setDuration] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");


  let fileReader;


  async function handleEvent() {
    const gpxLayer = new L2.GPX(file.current, {
      async: true
    }).on('loaded', function (e) {
      console.log("Parsing gpx");
      setRunName(e.target.get_name());
      setDistance(e.target.get_distance());
      setAuthor(e.target.get_author());
      setCadence(e.target.get_average_cadence());
      setElevation(e.target.get_elevation_gain());
      setElevationMin(e.target.get_elevation_min());
      setElevationMax(e.target.get_elevation_max());
      setDuration(e.target.get_moving_time());
      GpxParser.parseGpx(file.current, function (error, result) {
        if (!error) {
          if (result.tracks && result.tracks.length) {
            //We won't consider more than 1 track
            setStartPoint([result.tracks[0].segments[0][0].lat, result.tracks[0].segments[0][0].lon]);
            const numPoints = result.tracks[0].segments[0].length;
            setEndPoint([result.tracks[0].segments[0][numPoints-1].lat, result.tracks[0].segments[0][numPoints-1].lon]);
            console.log([result.tracks[0].segments[0][numPoints-1].lat, result.tracks[0].segments[0][numPoints-1][0].lon]);
          } else if (result.routes && result.routes.length) {
            //We won't consider more than 1 route
            //TODO Handle the use case where a route is uploaded
          }
        } else {
          //console.log("Parsing error");
          return error;
        }
      });

    });

  }


  function DisplayDuration(time) {
    let date = new Date(null);
    if (date.setSeconds(time)) {
      return date.toISOString().substr(11, 8);
    }

  }

  async function handleSubmit(event) {

  }


  function renderMap() {
    let runMap = null;
    if (distance) {
      runMap =        <Map
          eventFile={file.current}
          courseLat={0}
          courseLong={0}
          eventName={runName}
          onChange = {e => function () {

          }}
          isLoading={console.log("Loading map...")}
      />
      ;
    } else {
      runMap = "";
    }
    return runMap;
  }

  useEffect(() => {
    async function loadEvent() {
      const event = await API.get("events", `/events/${props.match.params.id}`);
      file.current = event.gpxData;
      handleEvent();
      //alert("Loaded run");
      return event;

    }

    async function onLoad() {
      try {
        const event = await loadEvent();
        //alert(event.eventName);
        setEvent(event);
      } catch (e) {
        alert("Couldn't find event: "+e);
      }
    }

    onLoad();
  }, [props.match.params.id]);


  return (
      <div className="Run">
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="Name">
            Name
            <FormControl
                value={event.eventName}
                type="text"
                placeholder="Name"
                onChange={e => setRunName(e.target.value)}
            />
          </FormGroup>
          {renderMap()}
          <FormGroup controlId="Distance">
            Distance
            <FormControl
                value={event.eventLength}
                type="text"
                placeholder="Distance"
                onChange={e => setDistance(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="Cadence">
            Cadence
            <FormControl
                value={cadence}
                type="text"
                placeholder="Cadence"
                onChange={e => setCadence(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="elevation">
            Elevation
            <FormControl
                value={elevation}
                type="text"
                placeholder="Elevation"
                onChange={e => setElevation(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="elevationMin">
            Elevation Min
            <FormControl
                value={elevationMin}
                type="text"
                placeholder="Elevation Min"
                onChange={e => setElevationMin(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="elevationMax">
            Elevation Max
            <FormControl
                value={elevationMax}
                type="text"
                placeholder="Elevation Max"
                onChange={e => setElevationMax(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="duration">
            Duration
            <FormControl
                value={DisplayDuration(event.duration)}
                type="text"
                placeholder="Duration"
                onChange={e => setDuration(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="start">
            Start
            <FormControl
                value={startPoint}
                type="text"
                placeholder="Start"
                onChange={e => setStartPoint(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="end">
            End
            <FormControl
                value={endPoint}
                type="text"
                placeholder="End"
                onChange={e => setEndPoint(e.target.value)}
            />
          </FormGroup>
        </form>
      </div>
  );
}
