
import { Auth } from 'aws-amplify';

export default async function getCurrentUserId() {
  const user = await Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }).catch(err => {
    console.log("Error while getting UserId: ",err);
    return null;
  });
  return user.attributes.sub;
}
