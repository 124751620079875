import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
// import logo from './Fas7erLogoW.png';
// import 'bootstrap/dist/css/bootstrap.min.css';


function App(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        console.log('userHasAuthenticated: props ', props)
        onLoad();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            console.log('userHasAuthenticated: true ')
            userHasAuthenticated(true);
        }
        catch(e) {
            if (e !== 'No current user') {
                alert(e);
            }
            console.log('userHasAuthenticated: e ', e)
        }
        setIsAuthenticating(false);
    }
    async function handleLogout() {
        await Auth.signOut();

        userHasAuthenticated(false);

        props.history.push("/login");
    }
    return (
        !isAuthenticating &&
        <div className="App container">
            <Navbar fluid collapseOnSelect>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/">FAS7ER</Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        {isAuthenticated
                            ? <>
                                <LinkContainer to="/users">
                                  <NavItem>Users</NavItem>
                                </LinkContainer>

                                <LinkContainer to="/garmin">
                                  <NavItem>Garmin Sync</NavItem>
                                </LinkContainer>
                                <NavItem onClick={handleLogout}>Logout</NavItem>
                              </>
                            : <>
                                <LinkContainer to="/signup">
                                    <NavItem>Signup</NavItem>
                                </LinkContainer>
                                <LinkContainer to="/login">
                                    <NavItem>Login</NavItem>
                                </LinkContainer>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
        </div>
    );
}

export default withRouter(App);
