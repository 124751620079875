export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-west-1",
    BUCKET: "faster-app-files"
  },
  apiGatewayPy: {
    REGION: "us-west-2",
    URL: "https://w8jrvoc3al.execute-api.us-west-2.amazonaws.com/dev/"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: " https://4bdgbl17o2.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_LPp4QeyNE",
    APP_CLIENT_ID: "6ts0jmadnj1iidtfhnf4uhj8m7",
    IDENTITY_POOL_ID: "us-west-2:8d872b2b-b563-497f-bca7-4b37dd96ca6a"
  }
};


// cognito: {
//   REGION: "us-west-2",
//   USER_POOL_ID: "us-west-2_wDo96nNCk",
//   APP_CLIENT_ID: "7qqfrf6ulihmlubph9gmptib4d",
//   IDENTITY_POOL_ID: "us-west-2:96c742f7-6da2-4afc-9580-2d661c0f07be"
// }
