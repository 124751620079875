import React, { useRef, useState } from "react";
import {FormGroup, FormControl, ControlLabel, ListGroupItem, ListGroup} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Config from "../config";
import "./NewEvent.css";
import { API, Auth } from "aws-amplify";
import GpxParser from "../libs/gpx-parse";
import L2 from "leaflet-gpx";
import Map from "../components/DisplayMap";
import NumberFormat from 'react-number-format';
import convert from 'convert-units';
import {LinkContainer} from "react-router-bootstrap";
import {Button} from "react-bootstrap";


export default function NewRun(props) {
  const file = useRef(null);
  const parentEventGpx = useRef(null);
  const [content, setContent] = useState("");
  const [runName, setRunName] = useState("");
  const [author, setAuthor] = useState("");
  const [cadence, setCadence] = useState("");
  const [distance, setDistance] = useState("");
  const [elevation, setElevation] = useState("");
  const [elevationMin, setElevationMin] = useState("");
  const [elevationMax, setElevationMax] = useState("");
  const [duration, setDuration] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startPoint, setStartPoint] = useState(false);
  const [endPoint, setEndPoint] = useState(false);
  const [validation, setValidation] = useState(false);
  const [runDateTime, setRunDateTime] = useState(false);
  const [nearbyEvents, setNearbyEvents] = useState(false);
  const [eventRuns, setEventRuns] = useState(null);


  let fileReader;

  async function loadEvent() {
    const event = await API.get("events", `/events/${props.match.params.id}`);
    parentEventGpx.current = event.records[0].gpxData;
    //console.log("Parent event: "+parentEvent.current);
  }

  async function LoadRuns() {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).catch(err => console.log(""));
    console.log("user", user)
    const userId = user.attributes.sub;
    console.log("*********** USERID: "+userId);
    var runs = await API.get("events", `/userRuns/${props.match.params.id}`);
    console.log("runs", runs)
  }

  const handleFileRead = (e) => {
    const fileContent = fileReader.result;
    //console.log(fileContent);
    file.current = fileContent;
    //console.log(fileContent);

    const gpxLayer = new L2.GPX(fileContent, {
      async: true
    }).on('loaded', function (e) {
      setRunName(e.target.get_name());
      setDistance(e.target.get_distance());
      setAuthor(e.target.get_author());
      setCadence(e.target.get_average_cadence());
      setElevation(e.target.get_elevation_gain());
      setElevationMin(e.target.get_elevation_min());
      setElevationMax(e.target.get_elevation_max());
      setDuration(e.target.get_moving_time());
      setRunDateTime(e.target.get_start_time().toString());
      GpxParser.parseGpx(fileContent, function (error, result) {
        if (!error) {
          if (result.tracks && result.tracks.length) {
            //We won't consider more than 1 track
            setStartPoint([result.tracks[0].segments[0][0].lat, result.tracks[0].segments[0][0].lon]);
            const numPoints = result.tracks[0].segments[0].length;
            setEndPoint([result.tracks[0].segments[0][numPoints-1].lat, result.tracks[0].segments[0][numPoints-1].lon]);
            console.log([result.tracks[0].segments[0][numPoints-1].lat, result.tracks[0].segments[0][numPoints-1][0].lon]);
          } else if (result.routes && result.routes.length) {
            //We won't consider more than 1 route
            //TODO Handle the use case where a route is uploaded
          }
        } else {
          //console.log("Parsing error");
          return error;
        }
      });



      const parentEventGpx =  loadEvent();;
      console.log("parentEventGpx: "+parentEventGpx);

    });

   };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  function validateForm() {
    if (validation > 0.8) {
      return true;
    } else {
      return false;
    }

  }

  function displayDuration() {
    let date = new Date(null);
    date.setSeconds(duration); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
  }
  function handleFileChange(event) {
    console.log("handleFileChange");
    file.current = event.target.files[0];
   }

  async function handleSubmit(event) {
    console.log("*********** HERE ");
    event.preventDefault();

    if (file.current && file.current.size > Config.MAX_ATTACHMENT_SIZE) {
      alert(
          `Please pick a file smaller than ${Config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).catch(err => console.log(err));
      const userId = user.attributes.sub;
      console.log("*********** USERID: "+userId);
      const attachment = "None";
      const gpxData = file.current;
      const eventName = runName;
      const eventLength = distance;
      const isRoute = 0;
      const content = "None";
      const parentEvent = props.match.params.id;
      const eventDate = Date.parse(runDateTime);
      await createEvent({ userId, content, attachment, isRoute, parentEvent, gpxData, startPoint, endPoint, eventName, eventLength, duration, eventDate});
      props.history.push("/events/"+props.match.params.id);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createEvent(event) {
    console.log(event);
    return API.post("events", "/events", {
      body: event
    });
  }

  function renderMap() {
    let runMap = null;
    if (distance) {
      runMap =        <Map
          eventFile={file.current}
          courseLat={0}
          courseLong={0}
          eventName={runName}
          onChange = {e => function () {

          }}
          isLoading={console.log("Loading map...")}
      />
      ;
    } else {
      runMap = "";
    }
    return runMap;
  }

  function displayPercentage(number) {
    return number * 100;
  }


  async function runTrackAnalysis() {
    //console.log("Running track analysis");
    try {
      const myInit = {
        headers: {
                "Access-Control-Allow-Credentials" : "True",
                "Access-Control-Allow-Origin": "*",
              }
      }
      let comparisonData = {
          gpxData1: parentEventGpx.current,
          gpxData2: file.current
      };
      const test = await API.post("compare", '/compare', {
          body: comparisonData,
          headers: ""
        }
      );
      //console.log(test);
      setValidation(test);
    } catch (e) {
      console.log("Error when calling compare API: "+e)
    }

  }

  async function findNearbyEvents() {
    //Define acceptable area of proximity
    const radius = 1000; //Meters
    let eventParameters = {
        point: startPoint,
        radius: radius
    };
    try {
      const nearbyEvents = await API.post("events", '/get-nearby', {
          body: eventParameters,
          headers: ""
        }
      );
      setNearbyEvents(nearbyEvents.records);
    } catch (e) {
      console.log("Error when searching nearby event through API: "+e)
    }
  }

  function displayNearbyEvents() {
    const events = nearbyEvents;
    return [{}].concat(events).map((event, i) =>
        i !== 0 ? (
            <LinkContainer key={event.eventId} to={`/events/${event.eventId}`}>
                <ListGroupItem header={event.eventName.trim().split("\n")[0]}>
                    {"Created: " + new Date(event.createdAt).toLocaleString()}
                </ListGroupItem>
            </LinkContainer>
        ) : (
            <nbsp/>
        )
    );
  }

  function renderEventName(eventName){
    console.log("eventName", eventName);
    return eventName
  }

  function renderEventDate(date) {
    if (date) {
      return new Date(date).toLocaleString();
    } else {
      return "not sure";
    }
  }

  function renderEventDuration(eventDuration){
    console.log("eventDuration", eventDuration);
      eventDuration = eventDuration/1000
    let date = new Date(null);
    if (date.setSeconds(eventDuration)) {
      return date.toISOString().substr(11, 8);
    }
  }

  function renderRunInfo() {
    let runInfo = null;
    if (distance) {
      runInfo = <ul>
          <li>Distance: <NumberFormat value={convert(distance).from('m').to('mi')} displayType={'text'} thousandSeparator={true} suffix={'mi'} decimalScale={2} /></li>
          <li>Date/time: {runDateTime}</li>
          <li>Cadence: <NumberFormat value={cadence} displayType={'text'} thousandSeparator={true} suffix={'m/min'} decimalScale={1} /></li>
          <li>Elevation gain: <NumberFormat value={convert(elevation).from('m').to('ft')} displayType={'text'} thousandSeparator={true} suffix={'ft'} decimalScale={0}/></li>
          <li>Elevation Min: <NumberFormat value={convert(elevationMin).from('m').to('ft')} displayType={'text'} thousandSeparator={true} suffix={'ft'} decimalScale={0}/></li>
          <li>Elevation Max: <NumberFormat value={convert(elevationMax).from('m').to('ft')} displayType={'text'} thousandSeparator={true} suffix={'ft'} decimalScale={0}/></li>
          <li>Duration: {displayDuration()}</li>
          <li>Starting point: [{startPoint}]</li>
          <li>End point: [{endPoint}]</li>
          <li>Percentage Match: <NumberFormat value={displayPercentage(validation)} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={0}/></li>
        </ul>
    }
    return  runInfo;
  }

  return (
    <div className="NewEvent">
    <ListGroup>
      {eventRuns && [{}].concat(eventRuns).map((event, i) =>
          i !== 0 ? (
              <LinkContainer key={"Runner: " + event.userName} to={`/run/show/${event.userName}/${props.match.params.id}`}>
                <ListGroupItem header={"Runner: " + event.userName.trim().split("\n")[0]}>
                <div>{"Event Name: " + renderEventName(event.eventName)} </div>
                  <div>{"Time : " + renderEventDuration(event.duration)} </div>
                    {"Ran on: " + renderEventDate(event.eventDate)}
                </ListGroupItem>
              </LinkContainer>
          ) : (
              <LinkContainer
                  key="new"
                  to={`/run/new/${props.match.params.id}`}
              >
                <ListGroupItem>
                  <h4>
                    <b>{"\uFF0B"}</b> Add a new run for this event
                  </h4>
                </ListGroupItem>
              </LinkContainer>
          )
      )}
       </ListGroup>
    <Button
        block
        bsSize="large"
        bsStyle="primary"
        variant="primary"
        disabled = {!parentEventGpx}
        onClick={ () => LoadRuns() }
    >
      Load Runs
    </Button>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="file">
          <ControlLabel>Let's start with a gpx file</ControlLabel>
          <FormControl onChange={e => handleFileChosen(e.target.files[0])} type="file" />
        </FormGroup>
        <FormGroup controlId="Name">
          Name
          <FormControl
              value={runName}
              type="text"
              placeholder="Name"
              onChange={e => setRunName(e.target.value)}
          />
        </FormGroup>
        {renderMap()}
        <br/>
        {renderRunInfo()}

        <Button
            block
            bsSize="large"
            bsStyle="info"
            variant="primary"
            disabled = {!parentEventGpx}
            onClick={ () => findNearbyEvents() }
        >
          Find Nearby Events
        </Button>
        {nearbyEvents && displayNearbyEvents()}
        <Button
            block
            bsSize="large"
            bsStyle="warning"
            variant="primary"
            disabled = {!parentEventGpx}
            onClick={ () => runTrackAnalysis() }
        >
          Validate
        </Button>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Save
        </LoaderButton>
      </form>
    </div>
  );
}
