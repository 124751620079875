import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import "./Home.css";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import renderEventList from "../components/RenderEventList";
import Map from "../components/DisplayMapOfEvents";

export default function Home(props) {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated) {
                return;
            }

            try {
                const events = await loadEvents();
                setEvents(events.records);
            } catch (e) {
                alert(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, [props.isAuthenticated]);

    function loadEvents() {
        return API.get("events", "/routes");
    }

    function renderLander() {
        return (
            <div className="lander">
                <h1>FAS7ER</h1>
                <p>Running Communities</p>
            </div>
        );
    }

    function renderMap() {
      console.log("Rendering Map");
      return (<Map
            events = {events}
            courseLat={0}
            courseLong={0}
            eventName={""}
            isLoading={console.log("Loading map...")}
      />);
    }

    function renderEvents() {
        return (
            <div className="events">
                <PageHeader>All Community Events</PageHeader>
                {!isLoading && renderMap()}
                <ListGroup>
                    {!isLoading && renderEventList(events)}
                </ListGroup>
            </div>
        );
    }
    return (
        <div className="Home">
            {props.isAuthenticated ? renderEvents() : renderLander()}
        </div>
    );
}
