import moment from 'moment';
import convert from 'convert-units';

/**
 * isDomAvailable
 * @description Checks to see if the DOM is available by checking the existence of the window and document
 * @see https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/ExecutionEnvironment.js#L12
 */
export function isDomAvailable() {
  return typeof window !== 'undefined' && !!window.document && !!window.document.createElement;
}

export function mySqlPointToArray(mySQlPoint) {
  const temp = mySQlPoint.replace('POINT(','').replace(')','');
  const arr = temp.split(' ');
  return arr;
}


export function renderDate(date) {
  if (date) {
    var formatedDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    return formatedDate.format('ddd, MMM Do YYYY - h:mm a');
  } else {
    return "not sure";
  }
}

export function renderDistance(distance) {
  var newDistance = convert(distance).from('m').to('mi');
  newDistance = Math.round(newDistance * 10) / 10;
  return newDistance + " mi" ;
}

export function renderDuration(eventDuration){
  var newEventDuration = eventDuration/1000;
  let date = new Date(null);
  if (date.setSeconds(newEventDuration)) {
    return date.toISOString().substr(11, 8);
  }
}


// export default (isDomAvailable, mySqlPointToArray)
// , renderDate)
// , renderDistance);
