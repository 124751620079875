import React, { useState, useEffect } from 'react';
// import { Spinner } from 'react-bootstrap'
import { Spinner } from 'reactstrap';
import { API } from 'aws-amplify';
import getCurrentUserId from '../libs/getCurrentUserId';
import './Garmin.css';

async function onButtonClick(e, props) {
  const userId = await getCurrentUserId();
  // Get request token
  const requestToken = await getGarminRequestToken(userId);
  console.log('requestToken: ', requestToken);

  //Figure out callback URL
  const callbackURL = encodeURIComponent(window.location.href.replace('garmin', 'garmin/confirm'));
  console.log('callbackURL: ', callbackURL);


  // Display Garmin authorization Page
  let authenticationUrl = `https://connect.garmin.com/oauthConfirm?oauth_token=${requestToken}&oauth_callback=${callbackURL}`;
  window.location.replace(authenticationUrl);
}

async function onUnAuth(e, props) {
    const result = false;
}

function getHeaders(props) {
  const headers = props ? (Object.assign({}, props.customHeaders)):({});
  headers['Content-Type'] = 'application/json';
  return headers;
}

async function getGarminRequestToken(userId) {
  // const result1 = API.get('events', '/routes');

  // Get previously saved request token
  const result =  await API.get('events', '/garmin-request-token/' + userId);
  if (result.statusCode != 200) {
    return result;
  } else {
    return result.body;
  }

}

// Check if this user already authorized the integration
async function getGarminAuthStatus(props) {
  // Get userId
  const userId = await getCurrentUserId();
  console.log(userId);
  if (!userId) {
    return 0;
  }

  // Check if user already authorized this
  try {
    const result =  await API.get('events', '/garmin-auth-status/' + userId);
    if (result == 'Authorized') {
      return 1;
    } else {
      return 0;
    }
  } catch(e) {
    return 0;
  }
}

function renderUnauthorized(props) {
  return (
    <div>
      <div>
        In order for your Garmin data to automatically sync with Parti you will need
        to authorize this. After clicking the button below you will be redirected
        to Garmin authorization page and return back here once the process is complete.
      </div>
      <div className = 'garminButton'>
        <img src='/garmin-connect.jpg' height='100' onClick={(e) => onButtonClick(e, props)} />
      </div>
    </div>
  )
}


function renderAuthorized() {
  return (
    <div>
      Your Garmin data is syncing with Parti. To stop this go to your account on
      <a href='https://connect.garmin.com/'> Garmin Connect</a>, navigate to the Applications section
      under <b>Account Information</b> and click on the <b>Disconnect</b> button.
    </div>
  )
}

// Display spinner while waiting
function renderLoading() {
  return(
    <Spinner animation='border' role='status'/>
  )
}

export default function Garmin(props) {
  const [isAuthorized,setIsAuthorized] = useState(-1);

  useEffect(() => {
    getGarminAuthStatus(props)
    .then(results => {
      setIsAuthorized(results);
    })
  }, []);

  return (
    <div className='garmin'>
      { (isAuthorized == -1) && renderLoading() }
      { (isAuthorized == 0) && renderUnauthorized() }
      { (isAuthorized == 1) && renderAuthorized() }

    </div>
  );
}
