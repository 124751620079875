import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import L2 from 'leaflet-gpx';


function Map(props) {
    const markerPosition = [props.courseLat, props.courseLong];
    const eventFile = props.eventFile;
    const mapRef = useRef(null);
    let gpx = eventFile; // URL to your GPX file or the GPX itself

    const [eventName, setEventName] = useState("");

    useEffect(() => {
         mapRef.current = L.map('map', {
            center: markerPosition,
            zoom: 16,
            layers: [
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution:
                        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                }),
            ]
        });

        const gpxLayer =  new L2.GPX(gpx, {
            async: true,
            marker_options: {
                startIconUrl: '/images/pin-icon-start.png',
                endIconUrl: '/images/pin-icon-end.png',
                shadowUrl: '/images/pin-shadow.png'
            }
        }).on('loaded', function(e) {
            mapRef.current.fitBounds(e.target.getBounds());
        });

        gpxLayer.addTo(mapRef.current);
    }, []);


   return <div id='map'></div>
}

export default Map;
