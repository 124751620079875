import React, {useEffect, useRef, useState} from "react";
import getCurrentUserId from '../libs/getCurrentUserId';
import { API } from "aws-amplify";

// This is the page the user will get to when returning from the Garmin
// user authorization page. This will execute the second let of the oauth
// process and will invoke the API to execute the 3rd leg.

async function getAuthToken(props) {

  console.log('props: ', props);

  try {
    const query = new URLSearchParams(window.location.href);
    var jsonStr ='{ "' + props.location.search.replace('?', '').replace(/&/g, '", "').replace(/=/g, '": "') + '"}';
    var parsedResponse = JSON.parse(jsonStr);
    console.log('parsedResponse: ', parsedResponse);
    const oauthToken = parsedResponse.oauth_token;
    const oauthVerifier = parsedResponse.oauth_verifier;
    const userId = await getCurrentUserId();

    // const oauthToken = props.oauth_token;
    console.log('window.location.href: ', window.location.href);

    console.log('get-token oauthToken: ', oauthToken);

    // const oauthVerifier = props.oauth_verifier;
    console.log('get-token oauthVerifier: ', oauthVerifier);

    // We got what we need to make the API call
    const result =  await API.get('events', '/garmin-auth-token/'+userId+'/'+oauthToken+'/'+oauthVerifier);
    console.log(result);

    return true;

  } catch(e) {
    console.log('An error occured: ',e);
    return false
  }
}

export default function GarminConfirm(props) {
  const [result, setResult] = useState(false);
  useEffect(() => {
    getAuthToken(props)
    .then(res => {
      setResult(res);
    })
  }, []);

  return (
      <div style={{ align: 'center' }} className="garmin">
        {result ? "Your Garmin and Parti accounts have been successfully linked!" : "An error occured while registering with Garmin"}
      </div>
  );
}
