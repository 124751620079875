import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import Signup from "./containers/Signup";
import NewEvent from "./containers/NewEvent";
import Events from "./containers/Events";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Course from "./containers/Course";
import NewRun from "./containers/NewRun";
import Run from "./containers/Run"
import Garmin from "./containers/Garmin"
import GarminConfirm from "./containers/GarminConfirm"
import Users from "./containers/Users"
import UserInfo from "./containers/UserInfo"

export default function Routes({ appProps }) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Home} appProps={appProps} />
            <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
            <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />
            <AuthenticatedRoute path="/users" exact component={Users} appProps={appProps} />
            <AuthenticatedRoute path="/events/new" exact component={NewEvent} appProps={appProps} />
            <AuthenticatedRoute path="/events/:id" exact component={Events} appProps={appProps} />
            <AuthenticatedRoute path="/course/" exact component={Course} appProps={appProps} />
            <AuthenticatedRoute path="/run/new/:id" exact component={NewRun} appProps={appProps} />
            <AuthenticatedRoute path="/run/show/:id/:parent" exact component={Run} appProps={appProps} />
            <AuthenticatedRoute path="/garmin" exact component={Garmin} appProps={appProps} />
            <AuthenticatedRoute path="/garmin/confirm" exact component={GarminConfirm} appProps={appProps} />
            <AuthenticatedRoute path="/user/:id" exact component={UserInfo} appProps={appProps} />

        )}
            { /* Finally, catch all unmatched routes */ }
            <Route component={NotFound} />
        </Switch>
    );
}
