import React, { useRef, useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import {FormGroup, FormControl, ControlLabel, ListGroupItem, ListGroup} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Events.css";
import { s3Upload } from "../libs/awsLib";
import {LinkContainer} from "react-router-bootstrap";
import Map from "../components/DisplayMap";
import {renderRunList} from "../components/RenderRunList";
import {renderDate as renderEventDate} from '../libs/util';
import {renderDuration as renderEventDuration} from '../libs/util';


export default function Events(props) {
  const file = useRef(null);
  const run = useRef(null);
  const [event, setEvent] = useState(null);
  const [eventRuns, setEventRuns] = useState(null);
  const [eventName, setEventName] = useState("");
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  let fileReader;

  const handleFileRead = (e) => {
    const fileContent = fileReader.result;
    //console.log(fileContent);
    file.current = fileContent;

  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };


  useEffect(() => {
    function loadEvent() {
      return API.get("events", `/events/${props.match.params.id}`);
    }

    function loadRuns() {
      return API.get("events", `/runs/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const event = await loadEvent();
        console.log('useEffect: event', event);
        //alert(event.eventName);
        setEvent(event.records[0]);
      } catch (e) {
        alert("Couldn't find event: "+e);
      }
      try {
        const eventRuns = await loadRuns(props.match.params.id);
        console.log("EventRuns");
        console.log(eventRuns);
        setEventRuns(eventRuns.records);
       } catch (e) {
        console.log("Couldn't find associated runs: "+e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function validateForm() {
    return content.length > 0;
  }

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  function saveEvent(event) {
    //console.log("Event GPX");
    //console.log(event.gpxData);
    return API.put("events", `/events/${props.match.params.id}`, {
      body: event
    });
  }

  async function handleSubmit(event) {
    let attachment;

    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
          `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      if (file.current) {
        attachment = await s3Upload(file.current);
      }

      await saveEvent({
        content,
        eventName,
        gpxData: file.current,
        attachment: attachment || event.attachment
      });
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function deleteEvent() {
    return API.del("events", `/events/${props.match.params.id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
        "Are you sure you want to delete this event?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteEvent();
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsDeleting(false);
    }
  }


  function renderUserName(userName){
    console.log("userName", userName);
      return userName
  }

  function renderEventName(eventName){
    console.log("eventName", eventName);
    return eventName
  }



  return (
      <div className="Events">
        {event && (
            <form onSubmit={handleSubmit}>
              <FormGroup controlId="Name">
                <FormControl
                  value={event.eventName}
                  type="text"
                  placeholder="Event Name"
                  onChange={e => setEventName(e.target.value)}
                  />
              </FormGroup>
              <FormGroup controlId="content">
                <FormControl
                    value={event.content}
                    componentClass="textarea"
                    size="sm"
                    placeholder="Provide some details about the event"
                    onChange={e => setContent(e.target.value)}
                />
              </FormGroup>
              <Map
                    eventFile={event.gpxData}
                    courseLat={0}
                    courseLong={0}
                    eventName={""}
                    isLoading={console.log("Loading map...")}
              />
              <div className="runs">
              <ListGroup>
                  Runs on this event:
                {eventRuns && [{}].concat(eventRuns).map((event, i) =>
                    i !== 0 ? (
                        <LinkContainer key={"Runner: " + event.userName} to={`/run/show/${event.userName}/${props.match.params.id}`}>
                          <ListGroupItem header={"Runner: " + event.userName.trim().split("\n")[0]}>
                          <div>{"Event Name: " + renderEventName(event.eventName)} </div>
                            <div>{"Time : " + renderEventDuration(event.duration)} </div>
                              {"Ran on: " + renderEventDate(event.eventDate)}
                          </ListGroupItem>
                        </LinkContainer>
                    ) : (
                        <LinkContainer
                            key={i}
                            to={`/run/new/${props.match.params.id}`}
                        >
                          <ListGroupItem>
                            <h4>
                              <b>{"\uFF0B"}</b> Add a new run for this event
                            </h4>
                          </ListGroupItem>
                        </LinkContainer>
                    )
                )}
                 </ListGroup>
              </div>
              <FormGroup controlId="file">
                <ControlLabel>Update event course</ControlLabel>
                <FormControl onChange={e => handleFileChosen(e.target.files[0])} type="file" />
              </FormGroup>
              <LoaderButton
                  block
                  type="submit"
                  bsSize="large"
                  bsStyle="primary"
                  isLoading={isLoading}
                  disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                  block
                  bsSize="large"
                  bsStyle="danger"
                  onClick={handleDelete}
                  isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </form>
        )}
      </div>

  );
}
