import React, {useEffect, useRef, useState} from "react";
import {PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import { API, Auth } from "aws-amplify";

export default function Users ({ props }) {
  const [userList, setUserList] = useState(null);
  const [loaded, setIsLoaded] = useState(false);


  useEffect(() => {
    async function getUserList(){
        var userInfo = await API.get("events","/users");
        console.log("userInfo of 0", userInfo.records[0])
        setUserList(userInfo.records);
        setIsLoaded(true);
    }

    if (!loaded)
      getUserList(userList);

  });

  return (
    <div className="Users">
      <ListGroup>
          Users
        { userList && [{}].concat(userList).map((user, i) => i>0 ? (
              <LinkContainer key={"User: " + i} to={"/user/" + user.userId}>
                <ListGroupItem header={user.userName}>
                  <div> {"User Name: " + user.firstName + " " + user.lastName} </div>
                  <div> {user.city + " , " + user.state} </div>
                </ListGroupItem>
              </LinkContainer>
            ) : (<div/>)
        )}
      </ListGroup>
    </div>
  );
}
