//import "../gpx-parser/src/gpx-parser";
// import "../gpx-parser/demo/script";
// import "../gpx-parser/demo/style.css"
import React from 'react'
//import { render } from 'react-dom'
//import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import "./Course.css"

import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';

class Map extends React.Component {
    render() {
        const position = [32.7157, 242.83];
        return (
             <LeafletMap
                center={position}
                zoom={12}
                maxZoom={20}
                attributionControl={true}
                zoomControl={true}
                doubleClickZoom={true}
                scrollWheelZoom={true}
                dragging={true}
                animate={true}
                easeLinearity={0.35}
            >
                <TileLayer
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                <Marker position={position}>
                    <Popup>
                        Popup for any custom information.
                    </Popup>
                </Marker>
            </LeafletMap>
        );
    }
}

export default Map

/*
export default function Course(props) {
    const position = [51.505, -0.09];

     return (
        //render(map, document.getElementById('map-container'))
        <div className="Course">
            <Map center={position} zoom={13}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                />
                <Marker position={position}>
                    <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
                </Marker>
            </Map>
        </div>
    )
}
*/

/*
export default function Course(props) {
    return (
        <div id="wrapper">
            <section role="main">
                <form id="loadGPXFile-form">
                    <h3>Choose a GPX file</h3>
                    <input type="file" id="loadGPXFile-input"/>
                        <input type="submit"/>
                </form>

                <div id="map">

                </div>

                <section id="metadata">
                    <h2 id="title"></h2>
                    <div id="part-author">
                        <h4>Author</h4>
                        <span id="authorname"></span><span id="authoremail"></span><span id="authorlink"></span>
                    </div>
                    <div id="part-waypoints">
                        <h4>Waypoints</h4>
                        <table>
                            <thead>
                            <tr>
                                <th>name</th>
                                <th>lat</th>
                                <th>lon</th>
                                <th>ele</th>
                                <th>cmt</th>
                                <th>desc</th>
                            </tr>
                            </thead>
                            <tbody id="waypoints">

                            </tbody>
                        </table>
                    </div>
                    <div id="part-tracks">
                        <h4>Tracks</h4>
                        <table>
                            <thead>
                            <tr>
                                <th>name</th>
                                <th>desc</th>
                                <th>distance</th>
                                <th>Avg elevation</th>
                                <th>Max elevation</th>
                                <th>Min elevation</th>
                                <th>Positive elevation difference</th>
                                <th>Negative elevation difference</th>

                            </tr>
                            </thead>
                            <tbody id="tracks">

                            </tbody>
                        </table>
                    </div>
                    <div id="part-routes">
                        <h4>Routes</h4>
                        <table>
                            <thead>
                            <tr>
                                <th>name</th>
                                <th>desc</th>
                                <th>distance</th>
                                <th>Avg elevation</th>
                                <th>Max elevation</th>
                                <th>Min elevation</th>
                                <th>Positive elevation difference</th>
                                <th>Negative elevation difference</th>

                            </tr>
                            </thead>
                            <tbody id="routes">

                            </tbody>
                        </table>
                    </div>
                </section>
            </section>
        </div>
    );
}*/
