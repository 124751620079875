import {LinkContainer} from "react-router-bootstrap";
import {ListGroupItem} from "react-bootstrap";
import convert from 'convert-units';
import React from "react";
import moment from 'moment';
import {renderDate as renderEventDate} from '../libs/util';
import {renderDistance as renderRunDistance} from '../libs/util';

function renderElevationGain(elevation) {
  console.log("elevation", elevation)
}

export default function renderEventList(events) {
    if (events) {
      // console.log("**** events : ",events);
      // console.log("distance.length: "+distance.length);
      var i;
      for (i = 0; i < events.length; i++) {
      //  events[i].eventLength;
        // console.log("Distance: ",events[i].eventLength);
      }

      return events.map((event, i) =>
          i !== 0 ? (
              <LinkContainer key={event.eventId} to={`/events/${event.eventId}`}>
                  <ListGroupItem header={event.eventName}>
                    <div>{"Event Date: " + renderEventDate(event.eventDate)} </div>
                    <div>{" Distance: " + renderRunDistance(event.eventLength)} </div>
                  </ListGroupItem>
              </LinkContainer>
          ) : (
            <div>
              <LinkContainer key="new" to="/events/new">
                    <ListGroupItem>
                        <h4>
                            <b>{"\uFF0B"}</b> Create a new event
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
                <LinkContainer key={event.eventId} to={`/events/${event.eventId}`}>
                    <ListGroupItem header={event.eventName}>
                        <div>{"Event Date: " + renderEventDate(event.eventDate)} </div>
                        <div>{" Distance: " + renderRunDistance(event.eventLength)} </div>
                    </ListGroupItem>
                </LinkContainer>
            </div>
          )
      );
    }
    else {
      return "";
    }
}
