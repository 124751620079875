import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import L2 from 'leaflet-gpx';
import {mySqlPointToArray} from '../libs/util';



function Map(props) {
    const events = props.events;
    const mapRef = useRef(null);
    const centerPosition = mySqlPointToArray(events[0].startPointStr);
    console.log("centerPosition "+centerPosition);
    console.log("events.length: "+ events.length);
    useEffect(() => {
         mapRef.current = L.map('map', {
            center: centerPosition,
            zoom: 16,
            layers: [
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution:
                        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                }),
            ]
        });
        console.log("Done with the map");
        var markerGroup = [];
        if (events) {
          for (var i=0; i<events.length; i++) {
              console.log("i: "+i);
              var popupText = events[i].eventName;
              var markerLocation = new L.latLng(mySqlPointToArray(events[i].startPointStr));
              var marker = new L.Marker(markerLocation);
              marker.bindPopup(popupText);
              marker.addTo(mapRef.current);
              markerGroup.push(marker);
              //mapRef.current.bindPopup(popupText);

          }
        }
        const featureGroup = new L.featureGroup(markerGroup);
        mapRef.current.fitBounds(featureGroup.getBounds());
    }, []);

   return <div id='map'></div>
}

export default Map;
